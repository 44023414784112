'use strict';

angular.module('tailor')
  .factory('authorizationInterceptor', function authorizationInterceptor($rootScope, $q, $injector) {
    function attachTokenToRequest(config) {
      const token = $rootScope.auth.signInUserSession.idToken.jwtToken;
      const auth0TokenString = localStorage
        .getItem('@@auth0spajs@@::'+ window.env.AUTH0_BA_CLIENT_ID +'::@@user@@');
      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
      }else if (auth0TokenString) {
        const auth0Token = JSON.parse(auth0TokenString);
        config.headers.Authorization = 'Bearer '+ auth0Token.id_token;
      }
    }
    return {
      request: function(config) {
        if(config.url.indexOf('/api') > -1 ) {
          attachTokenToRequest(config);
        }
        return config;
      },
      responseError: function(response) {
        // avoid circular dependency
        const $http = $injector.get('$http');
        const userService = $injector.get('userService');

        if (response.status === 401) {
          // retry
          return userService.refresh()
            .then(function() {
              attachTokenToRequest(response.config);
              response.config.isRetryRequest = true;
              return $http(response.config);
            })
            .catch(function(/* e*/) {
              // let the user service handle the error
              // and don't continue on to other interceptors
              // by returning an empty promise that never resolves
              return $q.defer().promise;
            });
        }
        return $q.reject(response);
      }
    };
  });
