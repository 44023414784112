'use strict';
angular.module('tailor').config(function configureStates($stateProvider, $urlRouterProvider) {
  // Handle errors encountered when navigating between pages
  if (
    $stateProvider &&
    $stateProvider.stateService &&
    $stateProvider.stateService.defaultErrorHandler
  ) {
    $stateProvider.stateService.defaultErrorHandler(function handlError(e) {
      if (e && e.detail) {
        Sentry && Sentry.captureException(e);
      }
    });
  }

  // Auth0 Integration: remove temporary login route
  $urlRouterProvider.when('/auth0-test-login', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('auth0test');
    }
  ]);

  $urlRouterProvider.when('/logout', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('logout');
    }
  ]);

  // Fallbacks!
  // If you navigate only to a configuration, we'll direct you to the first module or the modules section
  // if there are no required modules for the product you enabled.
  $urlRouterProvider.when('/configuration/:configurationId', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.setup', {
        configurationId: $match.configurationId,
        moduleId: '',
      });
    },
  ]);

  $urlRouterProvider.when('/configuration/:configurationId/eligibility', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.questions', {
        configurationId: parseInt($match.configurationId, 10),
      });
    },
  ]);
  $urlRouterProvider.when('/configuration/:configurationId/setup', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.setup', {
        configurationId: parseInt($match.configurationId, 10),
      });
    },
  ]);

  // If you navigate only to a module, we'll direct you to the first unit.
  $urlRouterProvider.when('/configuration/:configurationId/:moduleName', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.module.unit', {
        configurationId: $match.configurationId,
        moduleName: $match.moduleName,
        unitName: '',
        unitId: '',
      });
    },
  ]);
  $urlRouterProvider.when('/configuration/:configurationId/:moduleName/:unitName', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.module.unit', {
        configurationId: $match.configurationId,
        moduleName: $match.moduleName,
        unitName: $match.unitName,
        unitId: '',
      });
    },
  ]);
  // If you navigate only to a unit, we'll direct you to the first section.
  $urlRouterProvider.when('/configuration/:configurationId/:moduleName/:unitName/:unitId', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('configuration.module.unit.section', {
        configurationId: $match.configurationId,
        moduleName: $match.moduleName,
        unitName: $match.unitName,
        unitId: $match.unitId,
        sectionName: '',
      });
    },
  ]);

  $urlRouterProvider.when('/customers', [
    '$match',
    '$state',
    function ($match, $state) {
      $state.go('customers.list');
    },
  ]);

  // If we have no matching routes, go to the configurations page.
  $urlRouterProvider.otherwise(function ($injector, $location) {
    const path = $location.path();
    if (path !== '/configurations' && path !== '/') {
      return '/configurations';
    }
  });

  $stateProvider.state('configuration.module.upload', {
    url: '/:unitId/upload',
    views: {
      'detail@configuration': {
        component: 'upload',
      },
    },
    resolve: {
      configuration: [
        '$stateParams',
        'configurationService',
        function ($stateParams, configurationService) {
          return configurationService.get($stateParams.configurationId);
        },
      ],
      $uiRouter: [
        '$uiRouter',
        function ($uiRouter) {
          return $uiRouter;
        },
      ],
    },
  });

  // Customers pages:
  $stateProvider.state('customers', {
    url: '/customers',
    abstract: true,
    templateUrl: 'views/customers/page.html',
    resolve: {
      user: [
        'userService',
        function (userService) {
          // may return an infinitely pending promise
          // see .getToken within UserService
          return userService.getUser();
        },
      ],
    },
  });
  $stateProvider.state('customers.list', {
    url: '/list',
    views: {
      'customers@customers': {
        templateUrl: 'views/customers/customers.html',
        controller: 'CustomersCtrl',
      },
    },
    resolve: {
      customers: [
        'customerService',
        'loader',
        function (customerService, loader) {
          loader.open('Retrieving customers');
          return customerService.getCustomers().then(function (customers) {
            loader.close();
            return customers;
          });
        },
      ],
      pageTitle: [
        function () {
          return 'Customers';
        },
      ],
    },
  });
  $stateProvider.state('customers.new', {
    url: '/new',
    views: {
      'customers@customers': {
        controller: 'NewCustomerCtrl',
        templateUrl: 'views/customers/customer.html',
      },
    },
    resolve: {
      pageTitle: [
        function () {
          return 'New Customer';
        },
      ],
    },
  });
  $stateProvider.state('customers.edit', {
    url: '/:customerId',
    views: {
      'customers@customers': {
        controller: 'EditCustomerCtrl',
        templateUrl: 'views/customers/customer.html',
      },
    },
    resolve: {
      customer: [
        '$stateParams',
        'customerService',
        function ($stateParams, customerService) {
          return customerService.get($stateParams.customerId);
        },
      ],
      pageTitle: [
        'customer',
        function (customer) {
          return customer.name;
        },
      ],
    },
  });
  $stateProvider.state('customers.newConfiguration', {
    url: '/:customerId/new-configuration',
    views: {
      'customers@customers': {
        controller: 'NewConfigurationCtrl',
        templateUrl: 'views/configuration/edit-configuration.html',
      },
    },
    resolve: {
      customer: [
        '$stateParams',
        'customerService',
        function ($stateParams, customerService) {
          return customerService.get($stateParams.customerId);
        },
      ],
      configuration: [
        function () {
          return null;
        },
      ],
      uniqueUrls: [
        '$stateParams',
        'customerService',
        function ($stateParams, customerService) {
          return customerService.getCustomerUrls($stateParams.customerId);
        },
      ],
      products: [
        'productService',
        function (productService) {
          return productService.getProducts();
        },
      ],
      pageTitle: [
        function () {
          return 'New Configuration';
        },
      ],
    },
  });

  $stateProvider.state('customers.duplicateConfiguration', {
    url: '/:customerId/configurations/:configurationId/duplicate',
    views: {
      'customers@customers': {
        controller: 'NewConfigurationCtrl',
        templateUrl: 'views/configuration/edit-configuration.html',
      },
    },
    resolve: {
      customer: [
        '$stateParams',
        'customerService',
        function ($stateParams, customerService) {
          return customerService.get($stateParams.customerId);
        },
      ],
      uniqueUrls: [
        '$stateParams',
        'customerService',
        function ($stateParams, customerService) {
          return customerService.getCustomerUrls($stateParams.customerId);
        },
      ],
      configuration: [
        '$stateParams',
        'configurationService',
        function ($stateParams, configurationService) {
          return configurationService.get($stateParams.configurationId, false);
        },
      ],
      products: [
        'productService',
        function (productService) {
          return productService.getProducts();
        },
      ],
      pageTitle: [
        function () {
          return 'Duplicate Configuration';
        },
      ],
    },
  });

  $stateProvider.state('configurations', {
    url: '/configurations',
    templateUrl: 'views/customers/configurations.html',
    controller: 'ConfigurationsCtrl',
    resolve: {
      user: [
        'userService',
        'loader',
        function (userService, loader) {
          loader.open('Signin In');
          return userService.getUser().then(loader.close);
        },
      ],
      pageTitle: [
        function () {
          return 'Configurations';
        },
      ],
    },
  });

  // Configuration parent state:
  $stateProvider
    .state('configuration', {
      url: '/configuration/:configurationId',
      templateUrl: 'views/configuration/configuration.html',
      controller: 'ConfigurationCtrl',
      resolve: {
        user: [
          'userService',
          function (userService) {
            return userService.getUser();
          },
        ],
        configuration: [
          '$stateParams',
          'configurationService',
          'loader',
          function ($stateParams, configurationService, loader) {
            loader.open('Loading configuration');

            return configurationService
              .get($stateParams.configurationId)
              .then(function closeLoader(configuration) {
                loader.close();
                return configuration;
              });
          },
        ],
        customer: [
          'customerService',
          'configuration',
          function (customerService, configuration) {
            return customerService.get(configuration.CustomerId);
          },
        ],
        products: [
          'productService',
          function (productService) {
            return productService.get();
          },
        ],
        pageTitle: [
          'configuration',
          function (configuration) {
            return configuration.name;
          },
        ],
        schema: [
          'moduleDefinitionService',
          function (moduleDefinitionService) {
            return moduleDefinitionService.fieldSchema();
          },
        ],
      },
    })
    .state('configuration.questions', {
      url: '/eligibility',
      views: {
        detail: {
          templateUrl: 'views/configuration/eligibility.html',
          controller: 'EligibilityCtrl',
        },
      },
      resolve: {
        questionsWithUsage: [
          '$stateParams',
          'configurationService',
          'loader',
          function ($stateParams, configurationService, loader) {
            loader.open('Finding question usages');

            return configurationService
              .getQuestionsWithUsage($stateParams.configurationId)
              .then(function closeLoader(questions) {
                loader.close();
                return questions;
              });
          },
        ],
      },
    });

  $stateProvider
    .state('configuration.module', {
      url: '/:moduleName',
      resolve: {
        module: [
          'configuration',
          '$stateParams',
          '$state',
          '$timeout',
          function (configuration, $stateParams, $state, $timeout) {
            let moduleName = $stateParams.moduleName;
            if (moduleName === '') {
              moduleName = _.first(configuration.Modules).name;
              $timeout(function () {
                $state.go('^.module', {
                  moduleName,
                });
              });
            }
            return _.find(configuration.Modules, { name: moduleName });
          },
        ],
      },
    })
    .state('configuration.module.unit', {
      url: '/:unitName/:unitId',
      views: {
        'detail@configuration': {
          templateUrl: 'views/configuration/unit.html',
          controller: 'UnitCtrl',
        },
      },
      resolve: {
        unit: [
          'unitService',
          'moduleDefinitionService',
          'module',
          '$stateParams',
          '$state',
          '$timeout',
          function (unitService, moduleDefinitionService, module, $stateParams, $state, $timeout) {
            let unitId = $stateParams.unitId;
            let unitName = $stateParams.unitName;

            if (unitId === '' || unitName === '') {
              unitName = $stateParams.unitName || _.first(module.Units).name;
              unitId = _.first(_.filter(module.Units, { name: unitName })).id;
              $timeout(function () {
                $state.go('^.unit', {
                  unitName,
                  unitId,
                });
              });
            }

            return unitService.get(unitId).then(function (unit) {
              return unitService.getComments(unitId).then(function (comments) {
                unit.comments = comments;
                return moduleDefinitionService.get(module).then(function (definition) {
                  unit.definition = _.find(definition.unitDefinitions, {
                    name: unit.name,
                  });
                  unitService.parseSectionsAndComments(unit);
                  return unit;
                });
              });
            });
          },
        ],
      },
    })
    .state('configuration.module.unit.upload', {
      url: '/upload',
      views: {
        'detail@configuration': {
          component: 'upload',
        },
      },
      resolve: {
        displayName: [
          'unitService',
          'unit',
          function (unitService, unit) {
            return unitService.getUnitDisplayName(unit);
          },
        ],
        $uiRouter: [
          '$uiRouter',
          function ($uiRouter) {
            return $uiRouter;
          },
        ],
      },
      componentBindings: {
        displayName: 'displayName', // Bind the resolved displayName
      },
    })
    .state('configuration.module.unit.section', {
      url: '/:sectionName',
      abstract: false,
      views: {
        form: {
          templateProvider: [
            'moduleDefinitionTemplateService',
            'configuration',
            'module',
            'unit',
            'section',
            function (moduleDefinitionTemplateService, configuration, module, unit, section) {
              const path = module.name + '.' + unit.name + '.' + section.name;
              return moduleDefinitionTemplateService.build(section.definition, path);
            },
          ],
          controller: 'SectionCtrl',
        },
      },
      resolve: {
        section: [
          'unit',
          '$stateParams',
          '$state',
          function (unit, $stateParams, $state) {
            let sectionName = $stateParams.sectionName;

            switch (sectionName) {
              case 'Import Plan Details':
                // Redirect to the uploader component
                $state.go('configuration.module.unit.upload', {
                  configurationId: $stateParams.configurationId,
                  moduleName: $stateParams.moduleName,
                  unitName: $stateParams.unitName,
                  unitId: unit.id || $stateParams.unitId,
                });
                return;

              case '':
                // If sectionName is empty, use the first section's name
                sectionName = _.first(unit.sections).name;
                break;

              default:
                break;
            }

            // Find and return the section or default to the first section
            return _.find(unit.sections, { name: sectionName }) || unit.sections[0];
          },
        ],
      },
    })
    .state('configuration.setup', {
      url: '/setup',
      views: {
        detail: {
          templateUrl: 'views/configuration/setup.html',
          controller: 'SetupCtrl',
        },
      },
      resolve: {
        uniqueUrls: [
          'customerService',
          'configuration',
          function (customerService, configuration) {
            return customerService.getCustomerUrls(configuration.CustomerId);
          },
        ],
      },
    })
    .state('configuration.publishing', {
      url: '/publishing',
      views: {
        detail: {
          templateUrl: 'views/configuration/publishing.html',
          controller: 'SetupCtrl',
        },
      },
      resolve: {
        uniqueUrls: [
          'customerService',
          'configuration',
          function (customerService, configuration) {
            return customerService.getCustomerUrls(configuration.CustomerId);
          },
        ],
      },
    });

  $stateProvider.state('auth0test', {
    url: '/auth0-test-login',
    templateUrl: 'views/auth0_login_page.html',
    controller: function ($scope, $window, auth0Service) {
      auth0Service.createClient().then(auth0Client => {

        $scope.onClick = function() {
          auth0Client.loginWithRedirect();
        };

        if (location.search.includes('state=') &&
          (location.search.includes('code=') ||
          location.search.includes('error='))) {

          auth0Client.handleRedirectCallback()
            .then(function() {
              $window.history.replaceState({}, '', window.location.origin);
              // Unsure why $location.path refuses to navigate browser
              $window.location.assign('/#!/configurations?&refresh=false');

            }).catch(err => {
              console.error('failed to handle auth0 redirect ', err);
            });
        }
      });

    },
  });

  $stateProvider.state('logout', {
    url: '/logout',
    controller: function ($rootScope) {
      const url = $rootScope.auth.getFQDNSignOut();
      window.location.assign(url);
    }
  });
  // TAILOR-2386- Hiding Editor Tab
  /* $stateProvider.state('editor', {
      url: '/editor/',
      templateUrl: 'views/editor/page.html',
      controller: function EditorCtrl($scope, schema, definition) {
        $scope.schema = schema;
        $scope.definition = definition;
      },
      resolve: {
        user: [
          'userService',
          function(userService) {
            return userService.getUser();
          },
        ],
        schema: [
          'moduleDefinitionService',
          'loader',
          function(moduleDefinitionService, loader) {
            loader.open('Loading schema');
            return moduleDefinitionService.fieldSchema().then(function(schema) {
              loader.close();
              return schema;
            });
          },
        ],
        definition: [
          function() {
            return {};
          },
        ],
      },
    });*/
});
