'use strict';

angular.module('tailor')
  .service('auth0Service', function() {
    const createClient = function () {
      let connectionName;
      if (["staging", "production"].includes(window.env)){
        connectionName = "jellyvision-builder-okta"
      }else{
        connectionName = "jellyvision-sandbox-builder"
      }

      return auth0.createAuth0Client({
        domain: 'https://sandbox-login.jellydevs.com',
        clientId: window.env.AUTH0_BA_CLIENT_ID,
        connection: connectionName, // must be an allowed connection in UI
        authorizationParams: {
          redirect_uri: window.location.origin + '/#!/auth0-test-login'
        },
        cacheLocation: 'localstorage'
      });
    };
    return { createClient };
  });
